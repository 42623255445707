// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Transport_Converter.ts
 * "UC-Server-Access-Protocol-Transport" ASN.1 stubs.
 * This file was generated by estos esnacc (V6.0.10, 14.08.2024)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { ConverterError, ConverterErrorType, ConverterErrors, TSConverter, IDecodeContext, IEncodeContext, INamedType } from "./TSConverterBase";
import * as ENetUC_Transport from "./ENetUC_Transport";
// [PrintTSImports]
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Auth from "./ENetUC_Auth";
import * as ENetUC_Common_Auth from "./ENetUC_Common_Auth";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Auth_Converter from "./ENetUC_Auth_Converter";
import * as ENetUC_Common_Auth_Converter from "./ENetUC_Common_Auth_Converter";
// [PrintTSRootTypes]
export const MODULE_NAME = "ENetUC_Transport_Converter";
export const MODULE_LASTCHANGE = "1970-01-01T00:00:00Z";
export const MODULE_MAJOR_VERSION = 8;
export const MODULE_MINOR_VERSION = 0;
export const MODULE_VERSION = "8.0.0";

// [PrintTSEncoderDecoderCode]
export class AsnStartTLSArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnStartTLSArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnStartTLSArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartTLSArgument");

		const t = {} as ENetUC_Transport.AsnStartTLSArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnStartTLSArgument";

		if (errors.validateResult(newContext, "AsnStartTLSArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartTLSArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartTLSArgument");

		let t: ENetUC_Transport.AsnStartTLSArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnStartTLSArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnStartTLSArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartTLSArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnStartTLSArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStartTLSArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartTLSArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnStartTLSArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartTLSArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartTLSArgument");

		let t: ENetUC_Transport.AsnStartTLSArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnStartTLSArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnStartTLSArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartTLSArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnStartTLSResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnStartTLSResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnStartTLSResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartTLSResult");

		const t = {} as ENetUC_Transport.AsnStartTLSResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnStartTLSResult";

		if (errors.validateResult(newContext, "AsnStartTLSResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartTLSResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartTLSResult");

		let t: ENetUC_Transport.AsnStartTLSResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnStartTLSResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnStartTLSResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartTLSResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnStartTLSResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStartTLSResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartTLSResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnStartTLSResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartTLSResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartTLSResult");

		let t: ENetUC_Transport.AsnStartTLSResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnStartTLSResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnStartTLSResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartTLSResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnStartMultiUserModeArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnStartMultiUserModeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnStartMultiUserModeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartMultiUserModeArgument");

		const t = {} as ENetUC_Transport.AsnStartMultiUserModeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnStartMultiUserModeArgument";

		if (errors.validateResult(newContext, "AsnStartMultiUserModeArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartMultiUserModeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartMultiUserModeArgument");

		let t: ENetUC_Transport.AsnStartMultiUserModeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnStartMultiUserModeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnStartMultiUserModeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartMultiUserModeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnStartMultiUserModeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStartMultiUserModeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartMultiUserModeArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnStartMultiUserModeArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartMultiUserModeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartMultiUserModeArgument");

		let t: ENetUC_Transport.AsnStartMultiUserModeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnStartMultiUserModeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnStartMultiUserModeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartMultiUserModeArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnStartMultiUserModeResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnStartMultiUserModeResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnStartMultiUserModeResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartMultiUserModeResult");

		const t = {} as ENetUC_Transport.AsnStartMultiUserModeResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnStartMultiUserModeResult";

		if (errors.validateResult(newContext, "AsnStartMultiUserModeResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartMultiUserModeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartMultiUserModeResult");

		let t: ENetUC_Transport.AsnStartMultiUserModeResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnStartMultiUserModeResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnStartMultiUserModeResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartMultiUserModeResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnStartMultiUserModeResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnStartMultiUserModeResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnStartMultiUserModeResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnStartMultiUserModeResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnStartMultiUserModeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnStartMultiUserModeResult");

		let t: ENetUC_Transport.AsnStartMultiUserModeResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnStartMultiUserModeResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnStartMultiUserModeResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnStartMultiUserModeResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCreateUserSessionArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCreateUserSessionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCreateUserSessionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateUserSessionArgument");

		const t = {} as ENetUC_Transport.AsnCreateUserSessionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCreateUserSessionArgument";
		const _negotiate = ENetUC_Auth_Converter.AsnNegotiateServerConnectionArgument_Converter.toJSON(s.negotiate, errors, newContext, "negotiate");
		if (_negotiate)
			t.negotiate = _negotiate;

		if (errors.validateResult(newContext, "AsnCreateUserSessionArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateUserSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateUserSessionArgument");

		let t: ENetUC_Transport.AsnCreateUserSessionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCreateUserSessionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCreateUserSessionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _negotiate = ENetUC_Auth_Converter.AsnNegotiateServerConnectionArgument_Converter.fromJSON(s.negotiate, errors, newContext, "negotiate", false);
			if (_negotiate)
				t.negotiate = _negotiate;
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCreateUserSessionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCreateUserSessionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateUserSessionArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _negotiate = ENetUC_Auth_Converter.AsnNegotiateServerConnectionArgument_Converter.toBER(s.negotiate, errors, newContext, "negotiate");
		if (!errors.hasNewErrors()) {
			if (_negotiate)
				t.push(_negotiate);
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateUserSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateUserSessionArgument");

		let t: ENetUC_Transport.AsnCreateUserSessionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCreateUserSessionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCreateUserSessionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _negotiate = ENetUC_Auth_Converter.AsnNegotiateServerConnectionArgument_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "negotiate"), errors, newContext, "negotiate");
			if (_negotiate)
				t.negotiate = _negotiate;
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCreateUserSessionResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCreateUserSessionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCreateUserSessionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateUserSessionResult");

		const t = {} as ENetUC_Transport.AsnCreateUserSessionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCreateUserSessionResult";
		TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext);
		const _negotiateresult = ENetUC_Auth_Converter.AsnNegotiateServerConnectionResult_Converter.toJSON(s.negotiateresult, errors, newContext, "negotiateresult");
		if (_negotiateresult)
			t.negotiateresult = _negotiateresult;

		if (errors.validateResult(newContext, "AsnCreateUserSessionResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateUserSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateUserSessionResult");

		let t: ENetUC_Transport.AsnCreateUserSessionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCreateUserSessionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCreateUserSessionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext, false);
			const _negotiateresult = ENetUC_Auth_Converter.AsnNegotiateServerConnectionResult_Converter.fromJSON(s.negotiateresult, errors, newContext, "negotiateresult", false);
			if (_negotiateresult)
				t.negotiateresult = _negotiateresult;
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCreateUserSessionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCreateUserSessionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateUserSessionResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sSessionID", "string", errors, newContext);
		const _negotiateresult = ENetUC_Auth_Converter.AsnNegotiateServerConnectionResult_Converter.toBER(s.negotiateresult, errors, newContext, "negotiateresult");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sSessionID, name: "u8sSessionID" }));
			if (_negotiateresult)
				t.push(_negotiateresult);
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateUserSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateUserSessionResult");

		let t: ENetUC_Transport.AsnCreateUserSessionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCreateUserSessionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCreateUserSessionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sSessionID", "Utf8String", errors, newContext);
			const _negotiateresult = ENetUC_Auth_Converter.AsnNegotiateServerConnectionResult_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "negotiateresult"), errors, newContext, "negotiateresult");
			if (_negotiateresult)
				t.negotiateresult = _negotiateresult;
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDestroyUserSessionArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnDestroyUserSessionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnDestroyUserSessionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyUserSessionArgument");

		const t = {} as ENetUC_Transport.AsnDestroyUserSessionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDestroyUserSessionArgument";
		TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnDestroyUserSessionArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyUserSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyUserSessionArgument");

		let t: ENetUC_Transport.AsnDestroyUserSessionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnDestroyUserSessionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnDestroyUserSessionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnDestroyUserSessionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnDestroyUserSessionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDestroyUserSessionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyUserSessionArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sSessionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sSessionID, name: "u8sSessionID" }));
		}

		if (errors.validateResult(newContext, "AsnDestroyUserSessionArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyUserSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyUserSessionArgument");

		let t: ENetUC_Transport.AsnDestroyUserSessionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnDestroyUserSessionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnDestroyUserSessionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sSessionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDestroyUserSessionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDestroyUserSessionResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnDestroyUserSessionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnDestroyUserSessionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyUserSessionResult");

		const t = {} as ENetUC_Transport.AsnDestroyUserSessionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDestroyUserSessionResult";

		if (errors.validateResult(newContext, "AsnDestroyUserSessionResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyUserSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyUserSessionResult");

		let t: ENetUC_Transport.AsnDestroyUserSessionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnDestroyUserSessionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnDestroyUserSessionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnDestroyUserSessionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnDestroyUserSessionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDestroyUserSessionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyUserSessionResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnDestroyUserSessionResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyUserSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyUserSessionResult");

		let t: ENetUC_Transport.AsnDestroyUserSessionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnDestroyUserSessionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnDestroyUserSessionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnDestroyUserSessionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUserSessionDestroyedArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnUserSessionDestroyedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnUserSessionDestroyedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserSessionDestroyedArgument");

		const t = {} as ENetUC_Transport.AsnUserSessionDestroyedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUserSessionDestroyedArgument";
		TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnUserSessionDestroyedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnUserSessionDestroyedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserSessionDestroyedArgument");

		let t: ENetUC_Transport.AsnUserSessionDestroyedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnUserSessionDestroyedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnUserSessionDestroyedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnUserSessionDestroyedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnUserSessionDestroyedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUserSessionDestroyedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUserSessionDestroyedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sSessionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sSessionID, name: "u8sSessionID" }));
		}

		if (errors.validateResult(newContext, "AsnUserSessionDestroyedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnUserSessionDestroyedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUserSessionDestroyedArgument");

		let t: ENetUC_Transport.AsnUserSessionDestroyedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnUserSessionDestroyedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnUserSessionDestroyedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sSessionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnUserSessionDestroyedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRegisterExternalServiceResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRegisterExternalServiceResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRegisterExternalServiceResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterExternalServiceResult");

		const t = {} as ENetUC_Transport.AsnRegisterExternalServiceResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRegisterExternalServiceResult";

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterExternalServiceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterExternalServiceResult");

		let t: ENetUC_Transport.AsnRegisterExternalServiceResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRegisterExternalServiceResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterExternalServiceResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRegisterExternalServiceResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRegisterExternalServiceResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterExternalServiceResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterExternalServiceResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterExternalServiceResult");

		let t: ENetUC_Transport.AsnRegisterExternalServiceResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRegisterExternalServiceResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRegisterExternalServiceResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetExternalServiceProfileArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnGetExternalServiceProfileArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnGetExternalServiceProfileArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetExternalServiceProfileArgument");

		const t = {} as ENetUC_Transport.AsnGetExternalServiceProfileArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetExternalServiceProfileArgument";
		TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetExternalServiceProfileArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetExternalServiceProfileArgument");

		let t: ENetUC_Transport.AsnGetExternalServiceProfileArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnGetExternalServiceProfileArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnGetExternalServiceProfileArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iFlags", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnGetExternalServiceProfileArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetExternalServiceProfileArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetExternalServiceProfileArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sToken", "string", errors, newContext);
		TSConverter.validateParam(s, "iFlags", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iVersion, name: "iVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sToken, name: "u8sToken" }));
			if (s.iFlags !== undefined)
				t.push(new asn1ts.Integer({ value: s.iFlags, name: "iFlags", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetExternalServiceProfileArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetExternalServiceProfileArgument");

		let t: ENetUC_Transport.AsnGetExternalServiceProfileArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnGetExternalServiceProfileArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnGetExternalServiceProfileArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sToken", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iFlags", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetExternalServiceProfileResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnGetExternalServiceProfileResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnGetExternalServiceProfileResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetExternalServiceProfileResult");

		const t = {} as ENetUC_Transport.AsnGetExternalServiceProfileResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetExternalServiceProfileResult";

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetExternalServiceProfileResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetExternalServiceProfileResult");

		let t: ENetUC_Transport.AsnGetExternalServiceProfileResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnGetExternalServiceProfileResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnGetExternalServiceProfileResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnGetExternalServiceProfileResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetExternalServiceProfileResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetExternalServiceProfileResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetExternalServiceProfileResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetExternalServiceProfileResult");

		let t: ENetUC_Transport.AsnGetExternalServiceProfileResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnGetExternalServiceProfileResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnGetExternalServiceProfileResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetExternalServiceProfileResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRegisterServerArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRegisterServerArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRegisterServerArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerArgument");

		const t = {} as ENetUC_Transport.AsnRegisterServerArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRegisterServerArgument";
		TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sServerName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sServerID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sServerPassword", "string", errors, newContext);
		const _presenceDomains = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.presenceDomains, errors, newContext, "presenceDomains");
		if (_presenceDomains)
			t.presenceDomains = _presenceDomains;
		TSConverter.fillJSONParam(s, t, "u8sSystemID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iInterfaceVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sJWTPublicKey", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "u8sHardwareID", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnRegisterServerArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerArgument");

		let t: ENetUC_Transport.AsnRegisterServerArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRegisterServerArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterServerArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sVersion", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sServerName", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sServerID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sServerPassword", "string", errors, newContext, false);
			const _presencedomains = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.presenceDomains, errors, newContext, "presenceDomains", false);
			if (_presencedomains)
				t.presenceDomains = _presencedomains;
			TSConverter.fillJSONParam(s, t, "u8sSystemID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iInterfaceVersion", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sJWTPublicKey", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "u8sHardwareID", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnRegisterServerArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRegisterServerArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRegisterServerArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sVersion", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sServerName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sServerID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sServerPassword", "string", errors, newContext);
		const _presenceDomains = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.presenceDomains, errors, newContext, "presenceDomains");
		TSConverter.validateParam(s, "u8sSystemID", "string", errors, newContext);
		TSConverter.validateParam(s, "iInterfaceVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sJWTPublicKey", "string", errors, newContext, true);
		TSConverter.validateParam(s, "u8sHardwareID", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iVersion, name: "iVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sVersion, name: "u8sVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sServerName, name: "u8sServerName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sServerID, name: "u8sServerID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sServerPassword, name: "u8sServerPassword" }));
			if (_presenceDomains)
				t.push(_presenceDomains);
			t.push(new asn1ts.Utf8String({ value: s.u8sSystemID, name: "u8sSystemID" }));
			t.push(new asn1ts.Integer({ value: s.iInterfaceVersion, name: "iInterfaceVersion" }));
			if (s.u8sJWTPublicKey !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sJWTPublicKey, name: "u8sJWTPublicKey", idBlock: { optionalID: 0 } }));
			if (s.u8sHardwareID !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sHardwareID, name: "u8sHardwareID", idBlock: { optionalID: 1 } }));
		}

		if (errors.validateResult(newContext, "AsnRegisterServerArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerArgument");

		let t: ENetUC_Transport.AsnRegisterServerArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRegisterServerArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRegisterServerArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sVersion", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sServerName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sServerID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sServerPassword", "Utf8String", errors, newContext);
			const _presencedomains = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "presenceDomains"), errors, newContext, "presenceDomains");
			if (_presencedomains)
				t.presenceDomains = _presencedomains;
			TSConverter.fillASN1Param(s, t, "u8sSystemID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iInterfaceVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sJWTPublicKey", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "u8sHardwareID", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnRegisterServerArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebServiceCapabilities_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebServiceCapabilities, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebServiceCapabilities & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceCapabilities");

		const t = {} as ENetUC_Transport.AsnWebServiceCapabilities & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebServiceCapabilities";
		TSConverter.fillJSONParam(s, t, "u8sType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "iValue", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnWebServiceCapabilities"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceCapabilities | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceCapabilities");

		let t: ENetUC_Transport.AsnWebServiceCapabilities | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebServiceCapabilities>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebServiceCapabilities["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sValue", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "iValue", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilities"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebServiceCapabilities | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebServiceCapabilities";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceCapabilities");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sValue", "string", errors, newContext, true);
		TSConverter.validateParam(s, "iValue", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sType, name: "u8sType" }));
			if (s.u8sValue !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.u8sValue, name: "u8sValue" }));
			if (s.iValue !== undefined)
				t.push(new asn1ts.Integer({ value: s.iValue, name: "iValue" }));
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilities"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceCapabilities | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceCapabilities");

		let t: ENetUC_Transport.AsnWebServiceCapabilities | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebServiceCapabilities.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebServiceCapabilities["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sValue", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "iValue", "Integer", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilities"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRegisterServerError_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRegisterServerError, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRegisterServerError & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerError");

		const t = {} as ENetUC_Transport.AsnRegisterServerError & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRegisterServerError";
		TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, newContext);
		if (s.redirectAddresses) {
			const _redirectAddresses = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.redirectAddresses, errors, newContext, "redirectAddresses");
			if (_redirectAddresses)
				t.redirectAddresses = _redirectAddresses;
		}

		if (errors.validateResult(newContext, "AsnRegisterServerError"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerError");

		let t: ENetUC_Transport.AsnRegisterServerError | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRegisterServerError>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterServerError["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, newContext, false);
			const _redirectaddresses = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.redirectAddresses, errors, newContext, "redirectAddresses", true);
			if (_redirectaddresses)
				t.redirectAddresses = _redirectaddresses;
		}

		if (errors.validateResult(newContext, "AsnRegisterServerError"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRegisterServerError | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRegisterServerError";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerError");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iErrorDetail", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sErrorString", "string", errors, newContext);
		const _redirectAddresses = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.redirectAddresses, errors, newContext, "redirectAddresses", 0);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iErrorDetail, name: "iErrorDetail" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sErrorString, name: "u8sErrorString" }));
			if (_redirectAddresses)
				t.push(_redirectAddresses);
		}

		if (errors.validateResult(newContext, "AsnRegisterServerError"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerError");

		let t: ENetUC_Transport.AsnRegisterServerError | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRegisterServerError.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRegisterServerError["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iErrorDetail", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sErrorString", "Utf8String", errors, newContext);
			t.redirectAddresses = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "redirectAddresses"), errors, newContext, "redirectAddresses", true);
		}

		if (errors.validateResult(newContext, "AsnRegisterServerError"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRegisterServerRedirectResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRegisterServerRedirectResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRegisterServerRedirectResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerRedirectResult");

		const t = {} as ENetUC_Transport.AsnRegisterServerRedirectResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRegisterServerRedirectResult";
		const _redirectAddresses = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.redirectAddresses, errors, newContext, "redirectAddresses");
		if (_redirectAddresses)
			t.redirectAddresses = _redirectAddresses;

		if (errors.validateResult(newContext, "AsnRegisterServerRedirectResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerRedirectResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerRedirectResult");

		let t: ENetUC_Transport.AsnRegisterServerRedirectResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRegisterServerRedirectResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterServerRedirectResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _redirectaddresses = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.redirectAddresses, errors, newContext, "redirectAddresses", false);
			if (_redirectaddresses)
				t.redirectAddresses = _redirectaddresses;
		}

		if (errors.validateResult(newContext, "AsnRegisterServerRedirectResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRegisterServerRedirectResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRegisterServerRedirectResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerRedirectResult");

		// [Print_BER_EncoderSeqDefCode]
		const _redirectAddresses = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.redirectAddresses, errors, newContext, "redirectAddresses");
		if (!errors.hasNewErrors()) {
			if (_redirectAddresses)
				t.push(_redirectAddresses);
		}

		if (errors.validateResult(newContext, "AsnRegisterServerRedirectResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerRedirectResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerRedirectResult");

		let t: ENetUC_Transport.AsnRegisterServerRedirectResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRegisterServerRedirectResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRegisterServerRedirectResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _redirectaddresses = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "redirectAddresses"), errors, newContext, "redirectAddresses");
			if (_redirectaddresses)
				t.redirectAddresses = _redirectaddresses;
		}

		if (errors.validateResult(newContext, "AsnRegisterServerRedirectResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebListenInterfaceStatus_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebListenInterfaceStatus, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebListenInterfaceStatus & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebListenInterfaceStatus");

		const t = {} as ENetUC_Transport.AsnWebListenInterfaceStatus & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebListenInterfaceStatus";
		TSConverter.fillJSONParam(s, t, "u8sInterfaceGUID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "listenStatus", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatus"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebListenInterfaceStatus | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebListenInterfaceStatus");

		let t: ENetUC_Transport.AsnWebListenInterfaceStatus | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebListenInterfaceStatus>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebListenInterfaceStatus["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sInterfaceGUID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "listenStatus", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatus"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebListenInterfaceStatus | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebListenInterfaceStatus";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebListenInterfaceStatus");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sInterfaceGUID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sType", "string", errors, newContext);
		TSConverter.validateParam(s, "listenStatus", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sInterfaceGUID, name: "u8sInterfaceGUID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sType, name: "u8sType" }));
			t.push(new asn1ts.Integer({ value: s.listenStatus, name: "listenStatus" }));
		}

		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatus"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebListenInterfaceStatus | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebListenInterfaceStatus");

		let t: ENetUC_Transport.AsnWebListenInterfaceStatus | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebListenInterfaceStatus.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebListenInterfaceStatus["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sInterfaceGUID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "listenStatus", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatus"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnTransportKeepAliveArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnTransportKeepAliveArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnTransportKeepAliveArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTransportKeepAliveArgument");

		const t = {} as ENetUC_Transport.AsnTransportKeepAliveArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnTransportKeepAliveArgument";
		TSConverter.fillJSONParam(s, t, "dummy", "null", errors, newContext);

		if (errors.validateResult(newContext, "AsnTransportKeepAliveArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTransportKeepAliveArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTransportKeepAliveArgument");

		let t: ENetUC_Transport.AsnTransportKeepAliveArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnTransportKeepAliveArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnTransportKeepAliveArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnTransportKeepAliveArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnTransportKeepAliveArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnTransportKeepAliveArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTransportKeepAliveArgument");

		// [Print_BER_EncoderSeqDefCode]
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Null({ name: "dummy" }));
		}

		if (errors.validateResult(newContext, "AsnTransportKeepAliveArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTransportKeepAliveArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTransportKeepAliveArgument");

		let t: ENetUC_Transport.AsnTransportKeepAliveArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnTransportKeepAliveArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnTransportKeepAliveArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "dummy", "Null", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnTransportKeepAliveArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebGetIceServersArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebGetIceServersArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebGetIceServersArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebGetIceServersArgument");

		const t = {} as ENetUC_Transport.AsnWebGetIceServersArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebGetIceServersArgument";
		TSConverter.fillJSONParam(s, t, "sTurnProviderConfig", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnWebGetIceServersArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebGetIceServersArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebGetIceServersArgument");

		let t: ENetUC_Transport.AsnWebGetIceServersArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebGetIceServersArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebGetIceServersArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sTurnProviderConfig", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebGetIceServersArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebGetIceServersArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebGetIceServersArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sTurnProviderConfig", "Uint8Array", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (s.sTurnProviderConfig !== undefined)
				t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.sTurnProviderConfig), name: "sTurnProviderConfig", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebGetIceServersArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebGetIceServersArgument");

		let t: ENetUC_Transport.AsnWebGetIceServersArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebGetIceServersArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebGetIceServersArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sTurnProviderConfig", "OctetString", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebIceServer_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebIceServer, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebIceServer & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebIceServer");

		const t = {} as ENetUC_Transport.AsnWebIceServer & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebIceServer";
		const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(s.urls, errors, newContext, "urls");
		if (_urls)
			t.urls = _urls;
		TSConverter.fillJSONParam(s, t, "username", "string", errors, newContext, true);
		TSConverter.fillJSONParam(s, t, "credential", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnWebIceServer"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebIceServer | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebIceServer");

		let t: ENetUC_Transport.AsnWebIceServer | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebIceServer>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebIceServer["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(s.urls, errors, newContext, "urls", false);
			if (_urls)
				t.urls = _urls;
			TSConverter.fillJSONParam(s, t, "username", "string", errors, newContext, true);
			TSConverter.fillJSONParam(s, t, "credential", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebIceServer"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebIceServer | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebIceServer";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebIceServer");

		// [Print_BER_EncoderSeqDefCode]
		const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.toBER(s.urls, errors, newContext, "urls");
		TSConverter.validateParam(s, "username", "string", errors, newContext, true);
		TSConverter.validateParam(s, "credential", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (_urls)
				t.push(_urls);
			if (s.username !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.username, name: "username", idBlock: { optionalID: 0 } }));
			if (s.credential !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.credential, name: "credential", idBlock: { optionalID: 1 } }));
		}

		if (errors.validateResult(newContext, "AsnWebIceServer"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebIceServer | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebIceServer");

		let t: ENetUC_Transport.AsnWebIceServer | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebIceServer.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebIceServer["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _urls = ENetUC_Common_Converter.UTF8StringList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "urls"), errors, newContext, "urls");
			if (_urls)
				t.urls = _urls;
			TSConverter.fillASN1Param(s, t, "username", "Utf8String", errors, newContext, true);
			TSConverter.fillASN1Param(s, t, "credential", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebIceServer"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebGetIceServersV2Argument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebGetIceServersV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebGetIceServersV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebGetIceServersV2Argument");

		const t = {} as ENetUC_Transport.AsnWebGetIceServersV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebGetIceServersV2Argument";
		TSConverter.fillJSONParam(s, t, "sTurnProviderConfig", "string", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnWebGetIceServersV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebGetIceServersV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebGetIceServersV2Argument");

		let t: ENetUC_Transport.AsnWebGetIceServersV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebGetIceServersV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebGetIceServersV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sTurnProviderConfig", "string", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebGetIceServersV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebGetIceServersV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebGetIceServersV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sTurnProviderConfig", "string", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (s.sTurnProviderConfig !== undefined)
				t.push(new asn1ts.Utf8String({ value: s.sTurnProviderConfig, name: "sTurnProviderConfig", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebGetIceServersV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebGetIceServersV2Argument");

		let t: ENetUC_Transport.AsnWebGetIceServersV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebGetIceServersV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebGetIceServersV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sTurnProviderConfig", "Utf8String", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnTokenVerifyArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnTokenVerifyArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnTokenVerifyArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTokenVerifyArgument");

		const t = {} as ENetUC_Transport.AsnTokenVerifyArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnTokenVerifyArgument";
		TSConverter.fillJSONParam(s, t, "sToken", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnTokenVerifyArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTokenVerifyArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTokenVerifyArgument");

		let t: ENetUC_Transport.AsnTokenVerifyArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnTokenVerifyArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnTokenVerifyArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "sToken", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnTokenVerifyArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnTokenVerifyArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTokenVerifyArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "sToken", "Uint8Array", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.OctetString({ valueHex: new TextEncoder().encode(s.sToken), name: "sToken" }));
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTokenVerifyArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTokenVerifyArgument");

		let t: ENetUC_Transport.AsnTokenVerifyArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnTokenVerifyArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnTokenVerifyArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "sToken", "OctetString", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnTokenVerifyResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnTokenVerifyResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnTokenVerifyResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTokenVerifyResult");

		const t = {} as ENetUC_Transport.AsnTokenVerifyResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnTokenVerifyResult";
		TSConverter.fillJSONParam(s, t, "stValidTo", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sDNPath", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sUserName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sSIPURI", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sobjectGUID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sPhoneSecondary", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCallRecordingNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMailboxNumber", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sHomeServer", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iUserStaticRights", "number", errors, newContext);
		const _asnUserContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(s.asnUserContact, errors, newContext, "asnUserContact");
		if (_asnUserContact)
			t.asnUserContact = _asnUserContact;
		const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag");
		if (_asnUserPropertyBag)
			t.asnUserPropertyBag = _asnUserPropertyBag;

		if (errors.validateResult(newContext, "AsnTokenVerifyResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTokenVerifyResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTokenVerifyResult");

		let t: ENetUC_Transport.AsnTokenVerifyResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnTokenVerifyResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnTokenVerifyResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			if (TSConverter.validateParam(s, "stValidTo", "string", errors, newContext, false))
				t.stValidTo = new Date(s.stValidTo);
			TSConverter.fillJSONParam(s, t, "iType", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sDNPath", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sUserName", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sSIPURI", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sobjectGUID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sPhoneSecondary", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCallRecordingNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sMailboxNumber", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sHomeServer", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iUserStaticRights", "number", errors, newContext, false);
			const _asnusercontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(s.asnUserContact, errors, newContext, "asnUserContact", false);
			if (_asnusercontact)
				t.asnUserContact = _asnusercontact;
			const _asnuserpropertybag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromJSON(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag", false);
			if (_asnuserpropertybag)
				t.asnUserPropertyBag = _asnuserpropertybag;
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnTokenVerifyResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnTokenVerifyResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTokenVerifyResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "stValidTo", "Date", errors, newContext);
		TSConverter.validateParam(s, "iType", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sDNPath", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sUserName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sSIPURI", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sobjectGUID", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sPhoneSecondary", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sCallRecordingNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sMailboxNumber", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sHomeServer", "string", errors, newContext);
		TSConverter.validateParam(s, "iUserStaticRights", "number", errors, newContext);
		const _asnUserContact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toBER(s.asnUserContact, errors, newContext, "asnUserContact");
		const _asnUserPropertyBag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toBER(s.asnUserPropertyBag, errors, newContext, "asnUserPropertyBag");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stValidTo), name: "stValidTo" }));
			t.push(new asn1ts.Integer({ value: s.iType, name: "iType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sDNPath, name: "u8sDNPath" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sUserName, name: "u8sUserName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sSIPURI, name: "u8sSIPURI" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sobjectGUID, name: "u8sobjectGUID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneNumber, name: "u8sPhoneNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sPhoneSecondary, name: "u8sPhoneSecondary" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCallRecordingNumber, name: "u8sCallRecordingNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMailboxNumber, name: "u8sMailboxNumber" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sHomeServer, name: "u8sHomeServer" }));
			t.push(new asn1ts.Integer({ value: s.iUserStaticRights, name: "iUserStaticRights" }));
			if (_asnUserContact)
				t.push(_asnUserContact);
			if (_asnUserPropertyBag)
				t.push(_asnUserPropertyBag);
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTokenVerifyResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTokenVerifyResult");

		let t: ENetUC_Transport.AsnTokenVerifyResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnTokenVerifyResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnTokenVerifyResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "stValidTo", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iType", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sDNPath", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sUserName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sSIPURI", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sobjectGUID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sPhoneSecondary", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCallRecordingNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMailboxNumber", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sHomeServer", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iUserStaticRights", "Integer", errors, newContext);
			const _asnusercontact = ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnUserContact"), errors, newContext, "asnUserContact");
			if (_asnusercontact)
				t.asnUserContact = _asnusercontact;
			const _asnuserpropertybag = ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "asnUserPropertyBag"), errors, newContext, "asnUserPropertyBag");
			if (_asnuserpropertybag)
				t.asnUserPropertyBag = _asnuserpropertybag;
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnTokenVerifyV2Argument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnTokenVerifyV2Argument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnTokenVerifyV2Argument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTokenVerifyV2Argument");

		const t = {} as ENetUC_Transport.AsnTokenVerifyV2Argument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnTokenVerifyV2Argument";
		TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnTokenVerifyV2Argument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTokenVerifyV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTokenVerifyV2Argument");

		let t: ENetUC_Transport.AsnTokenVerifyV2Argument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnTokenVerifyV2Argument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnTokenVerifyV2Argument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyV2Argument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnTokenVerifyV2Argument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnTokenVerifyV2Argument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnTokenVerifyV2Argument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sToken", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sToken, name: "u8sToken" }));
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyV2Argument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnTokenVerifyV2Argument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnTokenVerifyV2Argument");

		let t: ENetUC_Transport.AsnTokenVerifyV2Argument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnTokenVerifyV2Argument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnTokenVerifyV2Argument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sToken", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnTokenVerifyV2Argument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCheckConnectionArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCheckConnectionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCheckConnectionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCheckConnectionArgument");

		const t = {} as ENetUC_Transport.AsnCheckConnectionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCheckConnectionArgument";

		if (errors.validateResult(newContext, "AsnCheckConnectionArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCheckConnectionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCheckConnectionArgument");

		let t: ENetUC_Transport.AsnCheckConnectionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCheckConnectionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCheckConnectionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCheckConnectionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCheckConnectionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCheckConnectionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCheckConnectionArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnCheckConnectionArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCheckConnectionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCheckConnectionArgument");

		let t: ENetUC_Transport.AsnCheckConnectionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCheckConnectionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCheckConnectionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnCheckConnectionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCheckConnectionResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCheckConnectionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCheckConnectionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCheckConnectionResult");

		const t = {} as ENetUC_Transport.AsnCheckConnectionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCheckConnectionResult";
		TSConverter.fillJSONParam(s, t, "u8sServerVersion", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sProductVersion", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iProtocolVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iOEMID", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sCookie", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCheckConnectionResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCheckConnectionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCheckConnectionResult");

		let t: ENetUC_Transport.AsnCheckConnectionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCheckConnectionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCheckConnectionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sServerVersion", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sProductVersion", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iProtocolVersion", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "iOEMID", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sCookie", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCheckConnectionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCheckConnectionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCheckConnectionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCheckConnectionResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sServerVersion", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sProductVersion", "string", errors, newContext);
		TSConverter.validateParam(s, "iProtocolVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "iOEMID", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sCookie", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sServerVersion, name: "u8sServerVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sProductVersion, name: "u8sProductVersion" }));
			t.push(new asn1ts.Integer({ value: s.iProtocolVersion, name: "iProtocolVersion" }));
			t.push(new asn1ts.Integer({ value: s.iOEMID, name: "iOEMID" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sCookie, name: "u8sCookie" }));
		}

		if (errors.validateResult(newContext, "AsnCheckConnectionResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCheckConnectionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCheckConnectionResult");

		let t: ENetUC_Transport.AsnCheckConnectionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCheckConnectionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCheckConnectionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sServerVersion", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sProductVersion", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iProtocolVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iOEMID", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sCookie", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCheckConnectionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestCERTokenArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRequestCERTokenArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRequestCERTokenArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestCERTokenArgument");

		const t = {} as ENetUC_Transport.AsnRequestCERTokenArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestCERTokenArgument";

		if (errors.validateResult(newContext, "AsnRequestCERTokenArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRequestCERTokenArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestCERTokenArgument");

		let t: ENetUC_Transport.AsnRequestCERTokenArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRequestCERTokenArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRequestCERTokenArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnRequestCERTokenArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRequestCERTokenArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestCERTokenArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestCERTokenArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnRequestCERTokenArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRequestCERTokenArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestCERTokenArgument");

		let t: ENetUC_Transport.AsnRequestCERTokenArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRequestCERTokenArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRequestCERTokenArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnRequestCERTokenArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRequestCERTokenResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRequestCERTokenResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRequestCERTokenResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestCERTokenResult");

		const t = {} as ENetUC_Transport.AsnRequestCERTokenResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRequestCERTokenResult";
		TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnRequestCERTokenResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRequestCERTokenResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestCERTokenResult");

		let t: ENetUC_Transport.AsnRequestCERTokenResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRequestCERTokenResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRequestCERTokenResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnRequestCERTokenResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRequestCERTokenResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRequestCERTokenResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRequestCERTokenResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sToken", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sToken, name: "u8sToken" }));
		}

		if (errors.validateResult(newContext, "AsnRequestCERTokenResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRequestCERTokenResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRequestCERTokenResult");

		let t: ENetUC_Transport.AsnRequestCERTokenResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRequestCERTokenResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRequestCERTokenResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sToken", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnRequestCERTokenResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetTimeArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnGetTimeArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnGetTimeArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetTimeArgument");

		const t = {} as ENetUC_Transport.AsnGetTimeArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetTimeArgument";

		if (errors.validateResult(newContext, "AsnGetTimeArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetTimeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetTimeArgument");

		let t: ENetUC_Transport.AsnGetTimeArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnGetTimeArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnGetTimeArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetTimeArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnGetTimeArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetTimeArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetTimeArgument");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnGetTimeArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetTimeArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetTimeArgument");

		let t: ENetUC_Transport.AsnGetTimeArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnGetTimeArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnGetTimeArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnGetTimeArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnGetTimeResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnGetTimeResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnGetTimeResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetTimeResult");

		const t = {} as ENetUC_Transport.AsnGetTimeResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnGetTimeResult";
		TSConverter.fillJSONParam(s, t, "stSystemtime", "Date", errors, newContext);

		if (errors.validateResult(newContext, "AsnGetTimeResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetTimeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetTimeResult");

		let t: ENetUC_Transport.AsnGetTimeResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnGetTimeResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnGetTimeResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			if (TSConverter.validateParam(s, "stSystemtime", "string", errors, newContext, false))
				t.stSystemtime = new Date(s.stSystemtime);
		}

		if (errors.validateResult(newContext, "AsnGetTimeResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnGetTimeResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnGetTimeResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnGetTimeResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "stSystemtime", "Date", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stSystemtime), name: "stSystemtime" }));
		}

		if (errors.validateResult(newContext, "AsnGetTimeResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnGetTimeResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnGetTimeResult");

		let t: ENetUC_Transport.AsnGetTimeResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnGetTimeResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnGetTimeResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "stSystemtime", "AsnSystemTime", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnGetTimeResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCreateMgmtSessionArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCreateMgmtSessionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCreateMgmtSessionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateMgmtSessionArgument");

		const t = {} as ENetUC_Transport.AsnCreateMgmtSessionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCreateMgmtSessionArgument";
		TSConverter.fillJSONParam(s, t, "u8sAuthToken", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateMgmtSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateMgmtSessionArgument");

		let t: ENetUC_Transport.AsnCreateMgmtSessionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCreateMgmtSessionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCreateMgmtSessionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sAuthToken", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCreateMgmtSessionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCreateMgmtSessionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateMgmtSessionArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sAuthToken", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sAuthToken, name: "u8sAuthToken" }));
		}

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateMgmtSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateMgmtSessionArgument");

		let t: ENetUC_Transport.AsnCreateMgmtSessionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCreateMgmtSessionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCreateMgmtSessionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sAuthToken", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCreateMgmtSessionResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCreateMgmtSessionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCreateMgmtSessionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateMgmtSessionResult");

		const t = {} as ENetUC_Transport.AsnCreateMgmtSessionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCreateMgmtSessionResult";
		TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateMgmtSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateMgmtSessionResult");

		let t: ENetUC_Transport.AsnCreateMgmtSessionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCreateMgmtSessionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCreateMgmtSessionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCreateMgmtSessionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCreateMgmtSessionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateMgmtSessionResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sSessionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sSessionID, name: "u8sSessionID" }));
		}

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateMgmtSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateMgmtSessionResult");

		let t: ENetUC_Transport.AsnCreateMgmtSessionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCreateMgmtSessionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCreateMgmtSessionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sSessionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCreateMgmtSessionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDestroyMgmtSessionArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnDestroyMgmtSessionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnDestroyMgmtSessionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyMgmtSessionArgument");

		const t = {} as ENetUC_Transport.AsnDestroyMgmtSessionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDestroyMgmtSessionArgument";
		TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyMgmtSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyMgmtSessionArgument");

		let t: ENetUC_Transport.AsnDestroyMgmtSessionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnDestroyMgmtSessionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnDestroyMgmtSessionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnDestroyMgmtSessionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDestroyMgmtSessionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyMgmtSessionArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sSessionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sSessionID, name: "u8sSessionID" }));
		}

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyMgmtSessionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyMgmtSessionArgument");

		let t: ENetUC_Transport.AsnDestroyMgmtSessionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnDestroyMgmtSessionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnDestroyMgmtSessionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sSessionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnDestroyMgmtSessionResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnDestroyMgmtSessionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnDestroyMgmtSessionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyMgmtSessionResult");

		const t = {} as ENetUC_Transport.AsnDestroyMgmtSessionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnDestroyMgmtSessionResult";

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyMgmtSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyMgmtSessionResult");

		let t: ENetUC_Transport.AsnDestroyMgmtSessionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnDestroyMgmtSessionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnDestroyMgmtSessionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnDestroyMgmtSessionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnDestroyMgmtSessionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnDestroyMgmtSessionResult");

		// [Print_BER_EncoderSeqDefCode]

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnDestroyMgmtSessionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnDestroyMgmtSessionResult");

		let t: ENetUC_Transport.AsnDestroyMgmtSessionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnDestroyMgmtSessionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnDestroyMgmtSessionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
		}

		if (errors.validateResult(newContext, "AsnDestroyMgmtSessionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnMgmtSessionDestroyedArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnMgmtSessionDestroyedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnMgmtSessionDestroyedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMgmtSessionDestroyedArgument");

		const t = {} as ENetUC_Transport.AsnMgmtSessionDestroyedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnMgmtSessionDestroyedArgument";
		TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnMgmtSessionDestroyedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnMgmtSessionDestroyedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMgmtSessionDestroyedArgument");

		let t: ENetUC_Transport.AsnMgmtSessionDestroyedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnMgmtSessionDestroyedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnMgmtSessionDestroyedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sSessionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnMgmtSessionDestroyedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnMgmtSessionDestroyedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnMgmtSessionDestroyedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnMgmtSessionDestroyedArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sSessionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sSessionID, name: "u8sSessionID" }));
		}

		if (errors.validateResult(newContext, "AsnMgmtSessionDestroyedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnMgmtSessionDestroyedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnMgmtSessionDestroyedArgument");

		let t: ENetUC_Transport.AsnMgmtSessionDestroyedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnMgmtSessionDestroyedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnMgmtSessionDestroyedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sSessionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnMgmtSessionDestroyedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCreateAuthTokenResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCreateAuthTokenResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCreateAuthTokenResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateAuthTokenResult");

		const t = {} as ENetUC_Transport.AsnCreateAuthTokenResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCreateAuthTokenResult";
		const _loginResult = ENetUC_Common_Auth_Converter.AsnLoginV2Result_Converter.toJSON(s.loginResult, errors, newContext, "loginResult");
		if (_loginResult)
			t.loginResult = _loginResult;
		TSConverter.fillJSONParam(s, t, "u8sAuthSessionID", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnCreateAuthTokenResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateAuthTokenResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateAuthTokenResult");

		let t: ENetUC_Transport.AsnCreateAuthTokenResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCreateAuthTokenResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCreateAuthTokenResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _loginresult = ENetUC_Common_Auth_Converter.AsnLoginV2Result_Converter.fromJSON(s.loginResult, errors, newContext, "loginResult", false);
			if (_loginresult)
				t.loginResult = _loginresult;
			TSConverter.fillJSONParam(s, t, "u8sAuthSessionID", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnCreateAuthTokenResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCreateAuthTokenResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCreateAuthTokenResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateAuthTokenResult");

		// [Print_BER_EncoderSeqDefCode]
		const _loginResult = ENetUC_Common_Auth_Converter.AsnLoginV2Result_Converter.toBER(s.loginResult, errors, newContext, "loginResult");
		TSConverter.validateParam(s, "u8sAuthSessionID", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_loginResult)
				t.push(_loginResult);
			t.push(new asn1ts.Utf8String({ value: s.u8sAuthSessionID, name: "u8sAuthSessionID" }));
		}

		if (errors.validateResult(newContext, "AsnCreateAuthTokenResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateAuthTokenResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateAuthTokenResult");

		let t: ENetUC_Transport.AsnCreateAuthTokenResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCreateAuthTokenResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCreateAuthTokenResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _loginresult = ENetUC_Common_Auth_Converter.AsnLoginV2Result_Converter.fromBER(s.getValueByName("loginResult"), errors, newContext, "loginResult");
			if (_loginresult)
				t.loginResult = _loginresult;
			TSConverter.fillASN1Param(s, t, "u8sAuthSessionID", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnCreateAuthTokenResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebServiceCapabilitiesList_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebServiceCapabilitiesList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebServiceCapabilitiesList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceCapabilitiesList");

		const t = [] as ENetUC_Transport.AsnWebServiceCapabilitiesList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnWebServiceCapabilities_Converter.toJSON(se, errors, newContext, "AsnWebServiceCapabilities");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceCapabilitiesList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceCapabilitiesList");

		let t: ENetUC_Transport.AsnWebServiceCapabilitiesList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebServiceCapabilitiesList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Transport.AsnWebServiceCapabilitiesList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnWebServiceCapabilities_Converter.fromJSON(se, errors, newContext, "AsnWebServiceCapabilities", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebServiceCapabilitiesList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebServiceCapabilitiesList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceCapabilitiesList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnWebServiceCapabilities_Converter.toBER(s[id], errors, newContext, "AsnWebServiceCapabilities");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceCapabilitiesList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceCapabilitiesList");

		let t: ENetUC_Transport.AsnWebServiceCapabilitiesList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebServiceCapabilitiesList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Transport.AsnWebServiceCapabilitiesList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnWebServiceCapabilities_Converter.fromBER(se, errors, newContext, "AsnWebServiceCapabilities", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRegisterServerResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRegisterServerResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRegisterServerResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerResult");

		const t = {} as ENetUC_Transport.AsnRegisterServerResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRegisterServerResult";
		if (s.capabilities) {
			const _capabilities = AsnWebServiceCapabilitiesList_Converter.toJSON(s.capabilities, errors, newContext, "capabilities");
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnRegisterServerResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerResult");

		let t: ENetUC_Transport.AsnRegisterServerResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRegisterServerResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterServerResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _capabilities = AsnWebServiceCapabilitiesList_Converter.fromJSON(s.capabilities, errors, newContext, "capabilities", true);
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnRegisterServerResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRegisterServerResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRegisterServerResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerResult");

		// [Print_BER_EncoderSeqDefCode]
		const _capabilities = AsnWebServiceCapabilitiesList_Converter.toBER(s.capabilities, errors, newContext, "capabilities", true);
		if (!errors.hasNewErrors()) {
			if (_capabilities)
				t.push(_capabilities);
		}

		if (errors.validateResult(newContext, "AsnRegisterServerResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerResult");

		let t: ENetUC_Transport.AsnRegisterServerResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRegisterServerResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRegisterServerResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			t.capabilities = AsnWebServiceCapabilitiesList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "capabilities"), errors, newContext, "capabilities", true);
		}

		if (errors.validateResult(newContext, "AsnRegisterServerResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRegisterServerV2ResultChoice_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRegisterServerV2ResultChoice, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRegisterServerV2ResultChoice & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerV2ResultChoice");

		const t = {} as ENetUC_Transport.AsnRegisterServerV2ResultChoice & INamedType;

		// [Print_JSON_EncoderChoiceDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRegisterServerV2ResultChoice";
		if (s.result != null) {
			const _result = AsnRegisterServerResult_Converter.toJSON(s.result, errors, newContext, "result");
			if (_result)
				t.result = _result;
		} else if (s.redirect != null) {
			const _redirect = AsnRegisterServerRedirectResult_Converter.toJSON(s.redirect, errors, newContext, "redirect");
			if (_redirect)
				t.redirect = _redirect;
		} else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnRegisterServerV2ResultChoice"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerV2ResultChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerV2ResultChoice");

		let t: ENetUC_Transport.AsnRegisterServerV2ResultChoice | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRegisterServerV2ResultChoice>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterServerV2ResultChoice["initEmpty"].call(0);
			// [Print_JSON_DecoderChoiceDefCode]
			if (s.result !== undefined)
				t.result = AsnRegisterServerResult_Converter.fromJSON(s.result, errors, newContext, "result", false);
			else if (s.redirect !== undefined)
				t.redirect = AsnRegisterServerRedirectResult_Converter.fromJSON(s.redirect, errors, newContext, "redirect", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnRegisterServerV2ResultChoice"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRegisterServerV2ResultChoice | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.BaseBlock | undefined {
		name ||= "AsnRegisterServerV2ResultChoice";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		let t: asn1ts.BaseBlock | undefined;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterServerV2ResultChoice");

		// [Print_BER_EncoderChoiceDefCode]
		if (s.result)
			t = AsnRegisterServerResult_Converter.toBER(s.result, errors, newContext, "result", 0);
		else if (s.redirect)
			t = AsnRegisterServerRedirectResult_Converter.toBER(s.redirect, errors, newContext, "redirect", 1);
		else
			errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "property missing"));

		if (errors.validateResult(newContext, "AsnRegisterServerV2ResultChoice"))
			return t;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterServerV2ResultChoice | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterServerV2ResultChoice");

		let t: ENetUC_Transport.AsnRegisterServerV2ResultChoice | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRegisterServerV2ResultChoice.getASN1Schema, data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterServerV2ResultChoice["initEmpty"].call(0);
			// [Print_BER_DecoderChoiceDefCode]
			if (s.choiceName === "result" && asn1ts.Sequence.typeGuard(s))
				t.result = AsnRegisterServerResult_Converter.fromBER(s, undefined, newContext, "result", false);
			else if (s.choiceName === "redirect" && asn1ts.Sequence.typeGuard(s))
				t.redirect = AsnRegisterServerRedirectResult_Converter.fromBER(s, undefined, newContext, "redirect", false);
			else if (!(optional === true))
				errors.push(new ConverterError(ConverterErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (errors.validateResult(newContext, "AsnRegisterServerV2ResultChoice"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebServiceCapabilitiesChangedArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceCapabilitiesChangedArgument");

		const t = {} as ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebServiceCapabilitiesChangedArgument";
		if (s.capabilities) {
			const _capabilities = AsnWebServiceCapabilitiesList_Converter.toJSON(s.capabilities, errors, newContext, "capabilities");
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceCapabilitiesChangedArgument");

		let t: ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _capabilities = AsnWebServiceCapabilitiesList_Converter.fromJSON(s.capabilities, errors, newContext, "capabilities", true);
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebServiceCapabilitiesChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceCapabilitiesChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _capabilities = AsnWebServiceCapabilitiesList_Converter.toBER(s.capabilities, errors, newContext, "capabilities", true);
		if (!errors.hasNewErrors()) {
			if (_capabilities)
				t.push(_capabilities);
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceCapabilitiesChangedArgument");

		let t: ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			t.capabilities = AsnWebServiceCapabilitiesList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "capabilities"), errors, newContext, "capabilities", true);
		}

		if (errors.validateResult(newContext, "AsnWebServiceCapabilitiesChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebListenInterfaceStatusList_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebListenInterfaceStatusList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebListenInterfaceStatusList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebListenInterfaceStatusList");

		const t = [] as ENetUC_Transport.AsnWebListenInterfaceStatusList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnWebListenInterfaceStatus_Converter.toJSON(se, errors, newContext, "AsnWebListenInterfaceStatus");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatusList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebListenInterfaceStatusList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebListenInterfaceStatusList");

		let t: ENetUC_Transport.AsnWebListenInterfaceStatusList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebListenInterfaceStatusList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Transport.AsnWebListenInterfaceStatusList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnWebListenInterfaceStatus_Converter.fromJSON(se, errors, newContext, "AsnWebListenInterfaceStatus", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatusList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebListenInterfaceStatusList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebListenInterfaceStatusList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebListenInterfaceStatusList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnWebListenInterfaceStatus_Converter.toBER(s[id], errors, newContext, "AsnWebListenInterfaceStatus");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatusList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebListenInterfaceStatusList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebListenInterfaceStatusList");

		let t: ENetUC_Transport.AsnWebListenInterfaceStatusList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebListenInterfaceStatusList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Transport.AsnWebListenInterfaceStatusList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnWebListenInterfaceStatus_Converter.fromBER(se, errors, newContext, "AsnWebListenInterfaceStatus", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnWebListenInterfaceStatusList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebServiceStatusArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebServiceStatusArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebServiceStatusArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceStatusArgument");

		const t = {} as ENetUC_Transport.AsnWebServiceStatusArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebServiceStatusArgument";
		const _interfaceList = AsnWebListenInterfaceStatusList_Converter.toJSON(s.interfaceList, errors, newContext, "interfaceList");
		if (_interfaceList)
			t.interfaceList = _interfaceList;

		if (errors.validateResult(newContext, "AsnWebServiceStatusArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceStatusArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceStatusArgument");

		let t: ENetUC_Transport.AsnWebServiceStatusArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebServiceStatusArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebServiceStatusArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _interfacelist = AsnWebListenInterfaceStatusList_Converter.fromJSON(s.interfaceList, errors, newContext, "interfaceList", false);
			if (_interfacelist)
				t.interfaceList = _interfacelist;
		}

		if (errors.validateResult(newContext, "AsnWebServiceStatusArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebServiceStatusArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebServiceStatusArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebServiceStatusArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _interfaceList = AsnWebListenInterfaceStatusList_Converter.toBER(s.interfaceList, errors, newContext, "interfaceList");
		if (!errors.hasNewErrors()) {
			if (_interfaceList)
				t.push(_interfaceList);
		}

		if (errors.validateResult(newContext, "AsnWebServiceStatusArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebServiceStatusArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebServiceStatusArgument");

		let t: ENetUC_Transport.AsnWebServiceStatusArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebServiceStatusArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebServiceStatusArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _interfacelist = AsnWebListenInterfaceStatusList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "interfaceList"), errors, newContext, "interfaceList");
			if (_interfacelist)
				t.interfaceList = _interfacelist;
		}

		if (errors.validateResult(newContext, "AsnWebServiceStatusArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebIceServerList_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebIceServerList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebIceServerList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebIceServerList");

		const t = [] as ENetUC_Transport.AsnWebIceServerList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnWebIceServer_Converter.toJSON(se, errors, newContext, "AsnWebIceServer");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnWebIceServerList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebIceServerList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebIceServerList");

		let t: ENetUC_Transport.AsnWebIceServerList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebIceServerList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Transport.AsnWebIceServerList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnWebIceServer_Converter.fromJSON(se, errors, newContext, "AsnWebIceServer", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnWebIceServerList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebIceServerList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebIceServerList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebIceServerList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnWebIceServer_Converter.toBER(s[id], errors, newContext, "AsnWebIceServer");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnWebIceServerList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebIceServerList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebIceServerList");

		let t: ENetUC_Transport.AsnWebIceServerList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebIceServerList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Transport.AsnWebIceServerList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnWebIceServer_Converter.fromBER(se, errors, newContext, "AsnWebIceServer", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnWebIceServerList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnWebGetIceServersResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnWebGetIceServersResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnWebGetIceServersResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebGetIceServersResult");

		const t = {} as ENetUC_Transport.AsnWebGetIceServersResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnWebGetIceServersResult";
		const _iceServers = AsnWebIceServerList_Converter.toJSON(s.iceServers, errors, newContext, "iceServers");
		if (_iceServers)
			t.iceServers = _iceServers;
		TSConverter.fillJSONParam(s, t, "stValidTo", "Date", errors, newContext);
		TSConverter.fillJSONParam(s, t, "iTTL", "number", errors, newContext);

		if (errors.validateResult(newContext, "AsnWebGetIceServersResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebGetIceServersResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebGetIceServersResult");

		let t: ENetUC_Transport.AsnWebGetIceServersResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnWebGetIceServersResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnWebGetIceServersResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _iceservers = AsnWebIceServerList_Converter.fromJSON(s.iceServers, errors, newContext, "iceServers", false);
			if (_iceservers)
				t.iceServers = _iceservers;
			if (TSConverter.validateParam(s, "stValidTo", "string", errors, newContext, false))
				t.stValidTo = new Date(s.stValidTo);
			TSConverter.fillJSONParam(s, t, "iTTL", "number", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnWebGetIceServersResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnWebGetIceServersResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnWebGetIceServersResult");

		// [Print_BER_EncoderSeqDefCode]
		const _iceServers = AsnWebIceServerList_Converter.toBER(s.iceServers, errors, newContext, "iceServers");
		TSConverter.validateParam(s, "stValidTo", "Date", errors, newContext);
		TSConverter.validateParam(s, "iTTL", "number", errors, newContext);
		if (!errors.hasNewErrors()) {
			if (_iceServers)
				t.push(_iceServers);
			t.push(new asn1ts.Real({ value: TSConverter.getVariantTimeFromDateTime(s.stValidTo), name: "stValidTo" }));
			t.push(new asn1ts.Integer({ value: s.iTTL, name: "iTTL" }));
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnWebGetIceServersResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnWebGetIceServersResult");

		let t: ENetUC_Transport.AsnWebGetIceServersResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnWebGetIceServersResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnWebGetIceServersResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _iceservers = AsnWebIceServerList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "iceServers"), errors, newContext, "iceServers");
			if (_iceservers)
				t.iceServers = _iceservers;
			TSConverter.fillASN1Param(s, t, "stValidTo", "AsnSystemTime", errors, newContext);
			TSConverter.fillASN1Param(s, t, "iTTL", "Integer", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnWebGetIceServersResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnUCSCapabilitiesChangedArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnUCSCapabilitiesChangedArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnUCSCapabilitiesChangedArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUCSCapabilitiesChangedArgument");

		const t = {} as ENetUC_Transport.AsnUCSCapabilitiesChangedArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnUCSCapabilitiesChangedArgument";
		if (s.capabilities) {
			const _capabilities = AsnWebServiceCapabilitiesList_Converter.toJSON(s.capabilities, errors, newContext, "capabilities");
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnUCSCapabilitiesChangedArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnUCSCapabilitiesChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUCSCapabilitiesChangedArgument");

		let t: ENetUC_Transport.AsnUCSCapabilitiesChangedArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnUCSCapabilitiesChangedArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnUCSCapabilitiesChangedArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _capabilities = AsnWebServiceCapabilitiesList_Converter.fromJSON(s.capabilities, errors, newContext, "capabilities", true);
			if (_capabilities)
				t.capabilities = _capabilities;
		}

		if (errors.validateResult(newContext, "AsnUCSCapabilitiesChangedArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnUCSCapabilitiesChangedArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnUCSCapabilitiesChangedArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnUCSCapabilitiesChangedArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _capabilities = AsnWebServiceCapabilitiesList_Converter.toBER(s.capabilities, errors, newContext, "capabilities", true);
		if (!errors.hasNewErrors()) {
			if (_capabilities)
				t.push(_capabilities);
		}

		if (errors.validateResult(newContext, "AsnUCSCapabilitiesChangedArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnUCSCapabilitiesChangedArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnUCSCapabilitiesChangedArgument");

		let t: ENetUC_Transport.AsnUCSCapabilitiesChangedArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnUCSCapabilitiesChangedArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnUCSCapabilitiesChangedArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			t.capabilities = AsnWebServiceCapabilitiesList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "capabilities"), errors, newContext, "capabilities", true);
		}

		if (errors.validateResult(newContext, "AsnUCSCapabilitiesChangedArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCreateAuthTokenArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCreateAuthTokenArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCreateAuthTokenArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateAuthTokenArgument");

		const t = {} as ENetUC_Transport.AsnCreateAuthTokenArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCreateAuthTokenArgument";
		const _login = ENetUC_Common_Auth_Converter.AsnLoginV2Argument_Converter.toJSON(s.login, errors, newContext, "login");
		if (_login)
			t.login = _login;
		TSConverter.fillJSONParam(s, t, "u8sUCSID", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "role", "number", errors, newContext, true);

		if (errors.validateResult(newContext, "AsnCreateAuthTokenArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateAuthTokenArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateAuthTokenArgument");

		let t: ENetUC_Transport.AsnCreateAuthTokenArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCreateAuthTokenArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCreateAuthTokenArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			const _login = ENetUC_Common_Auth_Converter.AsnLoginV2Argument_Converter.fromJSON(s.login, errors, newContext, "login", false);
			if (_login)
				t.login = _login;
			TSConverter.fillJSONParam(s, t, "u8sUCSID", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "role", "number", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnCreateAuthTokenArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCreateAuthTokenArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCreateAuthTokenArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateAuthTokenArgument");

		// [Print_BER_EncoderSeqDefCode]
		const _login = ENetUC_Common_Auth_Converter.AsnLoginV2Argument_Converter.toBER(s.login, errors, newContext, "login");
		TSConverter.validateParam(s, "u8sUCSID", "string", errors, newContext);
		TSConverter.validateParam(s, "role", "number", errors, newContext, true);
		if (!errors.hasNewErrors()) {
			if (_login)
				t.push(_login);
			t.push(new asn1ts.Utf8String({ value: s.u8sUCSID, name: "u8sUCSID" }));
			if (s.role !== undefined)
				t.push(new asn1ts.Enumerated({ value: s.role, name: "role", idBlock: { optionalID: 0 } }));
		}

		if (errors.validateResult(newContext, "AsnCreateAuthTokenArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateAuthTokenArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateAuthTokenArgument");

		let t: ENetUC_Transport.AsnCreateAuthTokenArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCreateAuthTokenArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCreateAuthTokenArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			const _login = ENetUC_Common_Auth_Converter.AsnLoginV2Argument_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "login"), errors, newContext, "login");
			if (_login)
				t.login = _login;
			TSConverter.fillASN1Param(s, t, "u8sUCSID", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "role", "Enumerated", errors, newContext, true);
		}

		if (errors.validateResult(newContext, "AsnCreateAuthTokenArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnExternalServiceProperty_Converter {
	public static toJSON(s: ENetUC_Transport.AsnExternalServiceProperty, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnExternalServiceProperty & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnExternalServiceProperty");

		const t = {} as ENetUC_Transport.AsnExternalServiceProperty & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnExternalServiceProperty";
		TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sType", "string", errors, newContext);
		if (s.listExternalServiceProperty) {
			const _listExternalServiceProperty = AsnExternalServicePropertyList_Converter.toJSON(s.listExternalServiceProperty, errors, newContext, "listExternalServiceProperty");
			if (_listExternalServiceProperty)
				t.listExternalServiceProperty = _listExternalServiceProperty;
		}

		if (errors.validateResult(newContext, "AsnExternalServiceProperty"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnExternalServiceProperty | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnExternalServiceProperty");

		let t: ENetUC_Transport.AsnExternalServiceProperty | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnExternalServiceProperty>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnExternalServiceProperty["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sName", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sType", "string", errors, newContext, false);
			const _listexternalserviceproperty = AsnExternalServicePropertyList_Converter.fromJSON(s.listExternalServiceProperty, errors, newContext, "listExternalServiceProperty", true);
			if (_listexternalserviceproperty)
				t.listExternalServiceProperty = _listexternalserviceproperty;
		}

		if (errors.validateResult(newContext, "AsnExternalServiceProperty"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnExternalServiceProperty | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnExternalServiceProperty";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnExternalServiceProperty");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sName", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sType", "string", errors, newContext);
		const _listExternalServiceProperty = AsnExternalServicePropertyList_Converter.toBER(s.listExternalServiceProperty, errors, newContext, "listExternalServiceProperty", 0);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sName, name: "u8sName" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sType, name: "u8sType" }));
			if (_listExternalServiceProperty)
				t.push(_listExternalServiceProperty);
		}

		if (errors.validateResult(newContext, "AsnExternalServiceProperty"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnExternalServiceProperty | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnExternalServiceProperty");

		let t: ENetUC_Transport.AsnExternalServiceProperty | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnExternalServiceProperty.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnExternalServiceProperty["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sName", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sType", "Utf8String", errors, newContext);
			t.listExternalServiceProperty = AsnExternalServicePropertyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listExternalServiceProperty"), errors, newContext, "listExternalServiceProperty", true);
		}

		if (errors.validateResult(newContext, "AsnExternalServiceProperty"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnExternalServicePropertyList_Converter {
	public static toJSON(s: ENetUC_Transport.AsnExternalServicePropertyList, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnExternalServicePropertyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnExternalServicePropertyList");

		const t = [] as ENetUC_Transport.AsnExternalServicePropertyList;

		// [Print_JSON_EncoderSetOfDefCode]
		for (const id in s) {
			const se = s[id];
			if (se === undefined)
				continue;
			const val = AsnExternalServiceProperty_Converter.toJSON(se, errors, newContext, "AsnExternalServiceProperty");
			if (val)
				t.push(val);
		}

		if (errors.validateResult(newContext, "AsnExternalServicePropertyList"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnExternalServicePropertyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnExternalServicePropertyList");

		let t: ENetUC_Transport.AsnExternalServicePropertyList | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnExternalServicePropertyList>(data, errors, newContext, optional);
		if (s) {
			t = new ENetUC_Transport.AsnExternalServicePropertyList();
			// [Print_JSON_DecoderSetOfDefCode]
			for (const id in s) {
				const se = s[id];
				if (se === undefined)
					continue;
				const val = AsnExternalServiceProperty_Converter.fromJSON(se, errors, newContext, "AsnExternalServiceProperty", false);
				if (val)
					t.push(val);
			}
		}

		if (errors.validateResult(newContext, "AsnExternalServicePropertyList"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnExternalServicePropertyList | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnExternalServicePropertyList";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnExternalServicePropertyList");

		// [Print_BER_EncoderSetOfDefCode]
		for (const id in s) {
			const val = AsnExternalServiceProperty_Converter.toBER(s[id], errors, newContext, "AsnExternalServiceProperty");
			if (val)
				t.push(val);
		}


		if (errors.validateResult(newContext, "AsnExternalServicePropertyList"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnExternalServicePropertyList | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnExternalServicePropertyList");

		let t: ENetUC_Transport.AsnExternalServicePropertyList | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnExternalServicePropertyList.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = new ENetUC_Transport.AsnExternalServicePropertyList();
			// [Print_BER_DecoderSetOfDefCode]
			for (const se of s.valueBlock.value) {
				if (asn1ts.Sequence.typeGuard(se)) {
					const val = AsnExternalServiceProperty_Converter.fromBER(se, errors, newContext, "AsnExternalServiceProperty", optional);
					if (val)
						t.push(val);
				} else
					errors.push(new ConverterError(ConverterErrorType.PROPERTY_TYPEMISMATCH, newContext.context, "wrong type"));
			}
		}

		if (errors.validateResult(newContext, "AsnExternalServicePropertyList"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnRegisterExternalServiceArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnRegisterExternalServiceArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnRegisterExternalServiceArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterExternalServiceArgument");

		const t = {} as ENetUC_Transport.AsnRegisterExternalServiceArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnRegisterExternalServiceArgument";
		TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sServiceType", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sServiceId", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sServiceUrl", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext);
		if (s.listExternalServiceProperty) {
			const _listExternalServiceProperty = AsnExternalServicePropertyList_Converter.toJSON(s.listExternalServiceProperty, errors, newContext, "listExternalServiceProperty");
			if (_listExternalServiceProperty)
				t.listExternalServiceProperty = _listExternalServiceProperty;
		}

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterExternalServiceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterExternalServiceArgument");

		let t: ENetUC_Transport.AsnRegisterExternalServiceArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnRegisterExternalServiceArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnRegisterExternalServiceArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iVersion", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sServiceType", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sServiceId", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sServiceUrl", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sToken", "string", errors, newContext, false);
			const _listexternalserviceproperty = AsnExternalServicePropertyList_Converter.fromJSON(s.listExternalServiceProperty, errors, newContext, "listExternalServiceProperty", true);
			if (_listexternalserviceproperty)
				t.listExternalServiceProperty = _listexternalserviceproperty;
		}

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnRegisterExternalServiceArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnRegisterExternalServiceArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnRegisterExternalServiceArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iVersion", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sServiceType", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sServiceId", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sServiceUrl", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sToken", "string", errors, newContext);
		const _listExternalServiceProperty = AsnExternalServicePropertyList_Converter.toBER(s.listExternalServiceProperty, errors, newContext, "listExternalServiceProperty", 0);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iVersion, name: "iVersion" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sServiceType, name: "u8sServiceType" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sServiceId, name: "u8sServiceId" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sServiceUrl, name: "u8sServiceUrl" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sToken, name: "u8sToken" }));
			if (_listExternalServiceProperty)
				t.push(_listExternalServiceProperty);
		}

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnRegisterExternalServiceArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnRegisterExternalServiceArgument");

		let t: ENetUC_Transport.AsnRegisterExternalServiceArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnRegisterExternalServiceArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnRegisterExternalServiceArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iVersion", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sServiceType", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sServiceId", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sServiceUrl", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sToken", "Utf8String", errors, newContext);
			t.listExternalServiceProperty = AsnExternalServicePropertyList_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "listExternalServiceProperty"), errors, newContext, "listExternalServiceProperty", true);
		}

		if (errors.validateResult(newContext, "AsnRegisterExternalServiceArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNegotiateInterfaceVersionArgument_Converter {
	public static toJSON(s: ENetUC_Transport.AsnNegotiateInterfaceVersionArgument, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnNegotiateInterfaceVersionArgument & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateInterfaceVersionArgument");

		const t = {} as ENetUC_Transport.AsnNegotiateInterfaceVersionArgument & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNegotiateInterfaceVersionArgument";
		TSConverter.fillJSONParam(s, t, "u8sMinInterfaceVer", "string", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sMaxInterfaceVer", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionArgument"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnNegotiateInterfaceVersionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateInterfaceVersionArgument");

		let t: ENetUC_Transport.AsnNegotiateInterfaceVersionArgument | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnNegotiateInterfaceVersionArgument>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnNegotiateInterfaceVersionArgument["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "u8sMinInterfaceVer", "string", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sMaxInterfaceVer", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionArgument"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnNegotiateInterfaceVersionArgument | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNegotiateInterfaceVersionArgument";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateInterfaceVersionArgument");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "u8sMinInterfaceVer", "string", errors, newContext);
		TSConverter.validateParam(s, "u8sMaxInterfaceVer", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Utf8String({ value: s.u8sMinInterfaceVer, name: "u8sMinInterfaceVer" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sMaxInterfaceVer, name: "u8sMaxInterfaceVer" }));
		}

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionArgument"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnNegotiateInterfaceVersionArgument | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateInterfaceVersionArgument");

		let t: ENetUC_Transport.AsnNegotiateInterfaceVersionArgument | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnNegotiateInterfaceVersionArgument.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnNegotiateInterfaceVersionArgument["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "u8sMinInterfaceVer", "Utf8String", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sMaxInterfaceVer", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionArgument"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnNegotiateInterfaceVersionResult_Converter {
	public static toJSON(s: ENetUC_Transport.AsnNegotiateInterfaceVersionResult, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnNegotiateInterfaceVersionResult & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateInterfaceVersionResult");

		const t = {} as ENetUC_Transport.AsnNegotiateInterfaceVersionResult & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnNegotiateInterfaceVersionResult";
		TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sServerInterfaceVer", "string", errors, newContext);

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionResult"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnNegotiateInterfaceVersionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateInterfaceVersionResult");

		let t: ENetUC_Transport.AsnNegotiateInterfaceVersionResult | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnNegotiateInterfaceVersionResult>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnNegotiateInterfaceVersionResult["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "bSuccess", "boolean", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sServerInterfaceVer", "string", errors, newContext, false);
		}

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionResult"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnNegotiateInterfaceVersionResult | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnNegotiateInterfaceVersionResult";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnNegotiateInterfaceVersionResult");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "bSuccess", "boolean", errors, newContext);
		TSConverter.validateParam(s, "u8sServerInterfaceVer", "string", errors, newContext);
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Boolean({ value: s.bSuccess, name: "bSuccess" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sServerInterfaceVer, name: "u8sServerInterfaceVer" }));
		}

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionResult"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnNegotiateInterfaceVersionResult | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnNegotiateInterfaceVersionResult");

		let t: ENetUC_Transport.AsnNegotiateInterfaceVersionResult | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnNegotiateInterfaceVersionResult.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnNegotiateInterfaceVersionResult["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "bSuccess", "Boolean", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sServerInterfaceVer", "Utf8String", errors, newContext);
		}

		if (errors.validateResult(newContext, "AsnNegotiateInterfaceVersionResult"))
			return t;

		return undefined;
	}
}

// [PrintTSEncoderDecoderCode]
export class AsnCreateUserSessionError_Converter {
	public static toJSON(s: ENetUC_Transport.AsnCreateUserSessionError, errors?: ConverterErrors, context?: IEncodeContext, name?: string): ENetUC_Transport.AsnCreateUserSessionError & INamedType | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateUserSessionError");

		const t = {} as ENetUC_Transport.AsnCreateUserSessionError & INamedType;

		// [Print_JSON_EncoderSeqDefCode]
		if (newContext.bAddTypes)
			t._type = "AsnCreateUserSessionError";
		TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, newContext);
		TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, newContext);
		const _negotiateerror = ENetUC_Common_Converter.AsnRequestError_Converter.toJSON(s.negotiateerror, errors, newContext, "negotiateerror");
		if (_negotiateerror)
			t.negotiateerror = _negotiateerror;

		if (errors.validateResult(newContext, "AsnCreateUserSessionError"))
			return t;

		return undefined;
	}

	public static fromJSON(data: string | object | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateUserSessionError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateUserSessionError");

		let t: ENetUC_Transport.AsnCreateUserSessionError | undefined;
		const s = TSConverter.prepareJSONData<ENetUC_Transport.AsnCreateUserSessionError>(data, errors, newContext, optional);
		if (s) {
			t = ENetUC_Transport.AsnCreateUserSessionError["initEmpty"].call(0);
			// [Print_JSON_DecoderSeqDefCode]
			TSConverter.fillJSONParam(s, t, "iErrorDetail", "number", errors, newContext, false);
			TSConverter.fillJSONParam(s, t, "u8sErrorString", "string", errors, newContext, false);
			const _negotiateerror = ENetUC_Common_Converter.AsnRequestError_Converter.fromJSON(s.negotiateerror, errors, newContext, "negotiateerror", false);
			if (_negotiateerror)
				t.negotiateerror = _negotiateerror;
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionError"))
			return t;

		return undefined;
	}

	public static toBER(s: ENetUC_Transport.AsnCreateUserSessionError | undefined, errors?: ConverterErrors, context?: IEncodeContext, name?: string, optional?: boolean | number): asn1ts.Sequence | undefined {
		name ||= "AsnCreateUserSessionError";
		if (!s) {
			TSConverter.addMissingError(errors, context, name, optional);
			return undefined;
		}

		const result = new asn1ts.Sequence(TSConverter.getASN1TSConstructorParams(name, optional));
		const t = result.valueBlock.value;
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addEncodeContext(context, name, "AsnCreateUserSessionError");

		// [Print_BER_EncoderSeqDefCode]
		TSConverter.validateParam(s, "iErrorDetail", "number", errors, newContext);
		TSConverter.validateParam(s, "u8sErrorString", "string", errors, newContext);
		const _negotiateerror = ENetUC_Common_Converter.AsnRequestError_Converter.toBER(s.negotiateerror, errors, newContext, "negotiateerror");
		if (!errors.hasNewErrors()) {
			t.push(new asn1ts.Integer({ value: s.iErrorDetail, name: "iErrorDetail" }));
			t.push(new asn1ts.Utf8String({ value: s.u8sErrorString, name: "u8sErrorString" }));
			if (_negotiateerror)
				t.push(_negotiateerror);
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionError"))
			return result;

		return undefined;
	}

	public static fromBER(data: Uint8Array | asn1ts.BaseBlock | undefined, errors?: ConverterErrors, context?: IDecodeContext, name?: string, optional?: boolean): ENetUC_Transport.AsnCreateUserSessionError | undefined {
		errors ||= new ConverterErrors();
		errors.storeState();
		const newContext = TSConverter.addDecodeContext(context, name, "AsnCreateUserSessionError");

		let t: ENetUC_Transport.AsnCreateUserSessionError | undefined;
		const s = TSConverter.prepareASN1BERData(ENetUC_Transport.AsnCreateUserSessionError.getASN1Schema, data, errors, newContext, optional);
		if (asn1ts.Sequence.typeGuard(s)) {
			t = ENetUC_Transport.AsnCreateUserSessionError["initEmpty"].call(0);
			// [Print_BER_DecoderSeqDefCode]
			TSConverter.fillASN1Param(s, t, "iErrorDetail", "Integer", errors, newContext);
			TSConverter.fillASN1Param(s, t, "u8sErrorString", "Utf8String", errors, newContext);
			const _negotiateerror = ENetUC_Common_Converter.AsnRequestError_Converter.fromBER(s.getTypedValueByName(asn1ts.Sequence, "negotiateerror"), errors, newContext, "negotiateerror");
			if (_negotiateerror)
				t.negotiateerror = _negotiateerror;
		}

		if (errors.validateResult(newContext, "AsnCreateUserSessionError"))
			return t;

		return undefined;
	}
}
