import { EConfigTemplate, validators } from "ucconfig";

import { IJsonConfig } from "../web-shared-components/interfaces/interfaces";
import { TEnvironment, TLogLevel } from "../web-shared-components/interfaces/types";
import { theLogger } from "./globals";

interface IConfig {
	// static variables
	logInfoKeepDays: number;
	maxLogEntriesInFile: number;
	environment: TEnvironment;
	lokiEnabled: boolean;
	lokiHost: string;
	lokiAuth: string;
	lokiLogAsn1: boolean;
	logLevel: TLogLevel;
	oem?: string;
}

/**
 * This manager extends ucconfig main class.
 */
export class ConfigManager extends EConfigTemplate {
	private static instance: ConfigManager;
	private readonly _config: IConfig;

	/**
	 * The constructor
	 * @param env - variables from env file
	 */
	private constructor(env: Record<string, string>) {
		super("VITE_CONFIG", env);
		this._config = {
			// static config variables
			logInfoKeepDays: this.newProperty<number>("LOG_KEEP_DAYS", validators.validateInteger(), 45),
			environment: this.checkEnvironment(),
			maxLogEntriesInFile: 10000,
			lokiEnabled: this.newProperty<boolean>("LOKI_ENABLED", validators.validateBoolean(), false),
			lokiHost: this.newProperty<string>("LOKI_API_URL", validators.validateString(), ""),
			lokiAuth: this.newProperty<string>("LOKI_API_BASIC_AUTH", validators.validateString(), ""),
			lokiLogAsn1: this.newProperty<boolean>("LOKI_LOG_ASN1", validators.validateBoolean(), false),
			logLevel: "info"
		};
	}

	/**
	 * Gets instance of ConfigManager to use as singleton.
	 * @param env - variables from env file
	 * @returns - an instance of this class.
	 */
	public static getInstance(env: Record<string, string>): ConfigManager {
		if (!ConfigManager.instance) ConfigManager.instance = new ConfigManager(env);
		return ConfigManager.instance;
	}

	/**
	 * read the config.json from the public folder
	 */
	public async init(): Promise<void> {
		await this.readJsonConfig();
	}

	/**
	 * Get the config
	 * @returns - the config
	 */
	public get config(): IConfig {
		return this._config;
	}

	/**
	 * Check in which environment the application runs
	 * @returns - the environment as a TEnvironment type
	 */
	private checkEnvironment(): TEnvironment {
		let environment: TEnvironment;
		const url = window.location.href;
		if (url.includes("s3-website.eu-central-1.amazonaws.com") || url.includes("msteams.procall.de")) {
			if (url.includes("dev")) environment = "dev";
			else if (url.includes("stg")) environment = "stg";
			else environment = "prod";
		} else environment = "local";
		return environment;
	}

	/**
	 * read the config from the config.json
	 */
	private async readJsonConfig(): Promise<void> {
		try {
			const data = await fetch("./config.json");
			const info: IJsonConfig = (await data.json()) as IJsonConfig;
			theLogger.info("fetch config.json", "readJsonConfig", { className: "config.ts" }, { info });

			if (info.logLevel) this._config.logLevel = info.logLevel;

			if (info.oem) this._config.oem = info.oem;
		} catch (e) {
			theLogger.error("error fetch config.json", "readJsonConfig", { className: "config.ts" }, { e });
		}
	}
}
