/**
 * Convert the presenceState into a readable string
 *
 * @param presence - the presence state to check
 * @param useIDSLabel - return IDS label (pointer, not translated)
 * @returns - readable string
 */
export function iPresenceStateToString(presence?: number, useIDSLabel?: boolean): string {
	if (presence === undefined)
		return useIDSLabel ? "IDS_UNKNOWN" : "UNKNOWN";
	/*
		 CONTACT_STATE_UNCONTROLLED 0x00000000 // Kontakt ohne Präsenzinformation
		 CONTACT_STATE_AVAILABLE    0x00000001
		 CONTACT_STATE_BUSY         0x00000002
		 CONTACT_STATE_ABSENT       0x00000003
		 CONTACT_STATE_DONOTDISTURB 0x00000004
		 CONTACT_STATE_OUTOFORDER   0x00000007 // nur für schwarze Icons, die es für Kontakte eigentlich nicht gibt. Wird im Client benötigt aber nicht auf der Schnittstelle.
		 */
	let presenceString;
	switch (presence & 0x000000ff) {
		case 0x00000001:
			presenceString = useIDSLabel ? "IDS_PRESENCE_AVAILABLE" : "AVAILABLE";
			break;
		case 0x00000002:
			presenceString = useIDSLabel ? "IDS_PRESENCE_BUSY" : "BUSY";
			break;
		case 0x00000003:
			presenceString = useIDSLabel ? "IDS_PRESENCE_ABSENT" : "ABSENT";
			break;
		case 0x00000004:
			presenceString = useIDSLabel ? "IDS_PRESENCE_DND" : "DND";
			break;
		case 0x00000007:
			presenceString = "OUTOFORDER";
			break;
		default:
			presenceString = useIDSLabel ? "IDS_UNKNOWN" : "UNKNOWN";
			break;
	}

	const flags = presence & 0xffff0000;
	/*
	 AsnPresenceStateFlags
	 CONTACT_STATE_RINGING              0x01000000 //!> Eine Leitung des Kontaktes klingelt
	 CONTACT_STATE_APPOINTMENT          0x02000000 //!> Der Kontakt hat einen Termin
	 CONTACT_STATE_CALL                 0x04000000 //!> Der Kontakt ist im Gespräch
	 CONTACT_STATE_OFFLINE              0x08000000 //!> Der Kontakt ist nicht am Server angemeldet
	 CONTACT_STATE_NOTESET              0x10000000 //!> Der Kontakt hat eine Notiz gesetzt
	 CONTACT_STATE_IDLE                 0x20000000 //!> Der Kontakt ist Idle
	 CONTACT_STATE_MOBILEAVAILABILITY   0x40000000 //!> FIVE-311: Der Benutzer ist wahrscheinlicher mobil erreichbar
	 0x00020000 - The contact is participating a conference with our conferencing product (added with PCE 7.1) eCONFERENCING = 131072
	 */
	const additionalStates = [];
	if (flags & 0x01000000)
		additionalStates.push("RINGING");
	if (flags & 0x02000000)
		additionalStates.push("APPOINTMENT");
	if (flags & 0x04000000)
		additionalStates.push("CALL");
	if (flags & 0x08000000)
		additionalStates.push("OFFLINE");
	if (flags & 0x10000000)
		additionalStates.push("NOTESET");
	if (flags & 0x20000000)
		additionalStates.push("IDLE");
	if (flags & 0x40000000)
		additionalStates.push("MOBILEAVAILABILITY");
	if (flags & 0x00020000)
		additionalStates.push("CONFERENCING");

	// Falls Offline angegeben ist -> zeige grau an
	if (additionalStates.indexOf("OFFLINE") > -1)
		presenceString = useIDSLabel ? "IDS_PRESENCE_OFFLINE" : "OFFLINE";
	if (additionalStates.indexOf("IDLE") > -1)
		presenceString = useIDSLabel ? "IDS_PRESENCE_IDLE" : "IDLE";
	// Check other flags to return IDS label if parameter is set
	// During a call there's no dedicated color.
	// Give the priority to the next flags.
	// Call or meeting: meeting has lower priority, if there's a call we display the call
	if (additionalStates.indexOf("CONFERENCING") > -1)
		presenceString = useIDSLabel ? "IDS_HAS_MEETING" : presenceString;
	if (additionalStates.indexOf("RINGING") > -1)
		presenceString = useIDSLabel ? "IDS_CALL_RINGING" : "RINGING";
	if (additionalStates.indexOf("CALL") > -1)
		presenceString = useIDSLabel ? "IDS_CALL_CONNECTED" : presenceString;

	// Keep uppercase if it's needed for IDS, lowercase otherwise (to match with the UserPresenceIcon logic)
	return useIDSLabel ? presenceString : presenceString.toLowerCase();
}
