import { compact, debounce } from "lodash";

import {
	AsnFindContactsArgument,
	AsnFindContactsResult,
	AsnGetContactByEntryIDArgument,
	AsnGetContactByEntryIDResult,
	AsnNetDatabaseFindOptionIFlags,
	AsnNetDatabaseFindOptions,
	AsnPresenceStateFlags
} from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetROSEInterface";
import { AsnOptionalParameters } from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import {
	AsnGetAndSubscribePresenceArgument,
	AsnGetAndSubscribePresenceResult,
	AsnUnsubscribePresenceArgument,
	AsnUnsubscribePresenceResult,
	AsnUpdatePresenceArgument
} from "../../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_PresenceV2";
import { absentStateConverter } from "../../../web-shared-components/interfaces/converters/absentStateConverter";
import {
	appointmentEntryConverter,
	appointmentEntryListConverter
} from "../../../web-shared-components/interfaces/converters/appointmentEntryConverter";
import { IContactContainer, IMSTeamsEmail } from "../../../web-shared-components/interfaces/interfaces";
import { addSipPrefixIfNotExists } from "../../lib/commonHelper";
import { SocketTransport } from "../../session/SocketTransport";
import { getState, subscribe } from "../../zustand/store";
import { getChangedProperties, getReachability } from "./utils";

export const ContactsPerPage = 20;
export const ContactSearchLimit = 100;

// Simplified interface to handle the messages received through the websocket
export interface IContactManagerHandler {
	onResult_asnGetAndSubscribePresence(result: AsnGetAndSubscribePresenceResult): void;
	onEvent_asnUpdatePresence(result: AsnUpdatePresenceArgument): void;
}

/**
 *
 */
export class ContactManager implements IContactManagerHandler {
	// The singleton instance of this class
	private static instance: ContactManager;
	private socket: SocketTransport;
	private subscribedContactIDs: Set<string> = new Set();
	private attemptToSubscribeContactIDs: Set<string> = new Set();
	private cleanAttemptsToSubscribeTimer: NodeJS.Timer | null = null;

	/**
	 * Constructs ContactManager.
	 * Method is private as we follow the Singleton Approach using getInstance
	 */
	private constructor() {
		this.socket = SocketTransport.getInstance();
		this.socket.setContactManagerHandler(this);
		subscribe((state) => state.journalContactIds, debounce(this.getContactsState, 500));
		this.cleanAttemptsToSubscribeTimer = setInterval(this.cleanAttemptsToSubscribe.bind(this), 10000);
	}

	/**
	 * Gets instance of ContactManager to use as singleton.
	 * @returns - an instance of this class.
	 */
	public static getInstance(): ContactManager {
		if (!ContactManager.instance) ContactManager.instance = new ContactManager();

		return ContactManager.instance;
	}

	/**
	 * Init and resubscribe to the presences.
	 */
	public initAndResubscribe(): void {
		this.attemptToSubscribeContactIDs = new Set();
		this.subscribedContactIDs = new Set();
		this.getContactsState(getState().journalContactIds);
	}

	/**
	 * GetState Listener function
	 * @param contactIds - contactIds
	 */
	private getContactsState = (contactIds: Set<string>): void => {
		const partiesToSubscribe: string[] = [];

		contactIds.forEach((contactId) => {
			if (this.subscribedContactIDs.has(contactId)) return;

			this.attemptToSubscribeContactIDs.add(contactId);
			return partiesToSubscribe.push(contactId);
		});

		const removedContactIds = [...this.subscribedContactIDs].filter((contactId) => !contactIds.has(contactId));

		if (partiesToSubscribe.length) this.subscribePresence(partiesToSubscribe);

		if (removedContactIds.length) {
			void this.unsubscribePresence(removedContactIds).then((result) => {
				if ((result as AsnUnsubscribePresenceResult).iResult === 0) {
					removedContactIds.forEach((contactId) => {
						this.subscribedContactIDs.delete(contactId);
						this.attemptToSubscribeContactIDs.delete(contactId);
						getState().removeContacts(removedContactIds);
					});
				}
			});
		}
	};

	/**
	 * Add list of contact ids to attempt to subscribe
	 * @param contactIds - contactIds
	 */
	private subscribeUpdates = (contactIds: Set<string>): void => {
		const partiesToSubscribe: string[] = [];
		contactIds.forEach((contactId) => {
			if (this.subscribedContactIDs.has(contactId)) return;

			this.attemptToSubscribeContactIDs.add(contactId);
			return partiesToSubscribe.push(contactId);
		});

		// const removedContactIds = [...this.subscribedContactIDs].filter((contactId) => !contactIds.has(contactId));

		if (partiesToSubscribe.length) this.subscribePresence(partiesToSubscribe);

		// TODO: unsubscribe searched contacts again
		// if (removedContactIds.length) {
		// 	void this.unsubscribePresence(removedContactIds).then((result) => {
		// 		if ((result as AsnUnsubscribePresenceResult).iResult === 0) {
		// 			removedContactIds.forEach((contactId) => {
		// 				this.subscribedContactIDs.delete(contactId);
		// 				this.attemptToSubscribeContactIDs.delete(contactId);
		// 				getState().removeContacts(removedContactIds);
		// 			});
		// 		}
		// 	});
		// }
	};

	/**
	 *
	 *Subscribe to the presence of the ids
	 * @param idList - the IDs we want to subscribe to
	 */
	public subscribePresence(idList: string[]): void {
		if (idList.length === 0) return;

		const argument = new AsnGetAndSubscribePresenceArgument({
			seqContactIDs: idList,
			iEventFlagsToSubscribe:
				1 + // presence (ePresenceSubscriptionPresence)
				16 + // absence state (ePresenceSubscriptionAbsentstate)
				32 + // note (ePresenceSubscriptionNote)
				64 + // appointment (ePresenceSubscriptionAppointment)
				256 // phone calls (ePresenceSubscriptionPhoneCalls)
		});
		this.socket.send("asnGetAndSubscribePresence", argument);
	}

	/**
	 * Return event after subscribe
	 * @param result - the result we get from the server
	 */
	public onResult_asnGetAndSubscribePresence(result: AsnGetAndSubscribePresenceResult): void {
		result.seqSubscribedPresence.forEach((asnPresence) => this.subscribedContactIDs.add(asnPresence.u8sContactId));

		if (result.seqSubscribedPresence.length) {
			const contactContainers = result.seqSubscribedPresence.map((asnPresence) => {
				const contactContainer: IContactContainer = {
					contactID: asnPresence.u8sContactId,
					presence: asnPresence.iPresenceState,
					customNote: asnPresence.asnCustomNote?.u8sNote,
					nextAppointment: appointmentEntryConverter(asnPresence.asnNextAppointment),
					currentAppointments: appointmentEntryListConverter(asnPresence.seqActualAppointments),
					reachability: getReachability(asnPresence),
					agentState: asnPresence.asnAgentState?.dwState,
					absentState: absentStateConverter(asnPresence.asnAbsentState),
					isMobileAvailable: asnPresence.iPresenceState
						? !!(asnPresence.iPresenceState & AsnPresenceStateFlags.eMOBILEAVAILABLITY)
						: false,
					msTeamsEmail: this.getMSTeamsEmail(asnPresence.asnAbsentState?.optionalParams),
					clientCapabilities: asnPresence.asnContactCapabilitiesEx,
					seqCalls: asnPresence.seqCalls,
					seqLineForwards: asnPresence.seqLineForwards,
					seqPhoneLines: asnPresence.seqPhoneLines
				};
				return contactContainer;
			});
			const contactDetails = result.seqSubscribedPresence
				.map((asnPresence) => ({
					...{
						...asnPresence.asnRemoteContact,
						u8sDisplayName:
							asnPresence.asnRemoteContact?.u8sFirstName && asnPresence.asnRemoteContact.u8sLastName
								? asnPresence.asnRemoteContact?.u8sFirstName + " " + asnPresence.asnRemoteContact.u8sLastName
								: asnPresence.asnRemoteContact?.u8sDisplayName
					},
					u8sEntryID: addSipPrefixIfNotExists(asnPresence?.asnRemoteContact?.u8sSIPAddress)
				}))
				.filter((item) => item.u8sEntryID !== "");
			getState().setContactsPresence(contactContainers);
			getState().setContactsDetails(contactDetails);
		}
	}

	/**
	 *
	 *Unsubscribe to the presence of the ids
	 * @param idList - the IDs we want to subscribe to
	 */
	public unsubscribePresence(idList: string[]): Promise<AsnUnsubscribePresenceResult | Error> {
		if (idList.length === 0) return Promise.resolve(new AsnUnsubscribePresenceResult({ iResult: 0 }));

		const argument = new AsnUnsubscribePresenceArgument({
			seqContactIDs: idList
		});

		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) reject(result);
				else resolve(result as AsnUnsubscribePresenceResult);
			};
			this.socket.send("asnUnsubscribePresence", argument, callBack);
		});
	}

	/**
	 *	get the presence update event and dispatch the redux change
	 * @param result - the result
	 */
	public onEvent_asnUpdatePresence(result: AsnUpdatePresenceArgument): void {
		const [changedProperties, remoteContactUpdate] = getChangedProperties(result);
		if (remoteContactUpdate) {
			getState().setContactsDetails([
				{
					u8sEntryID: addSipPrefixIfNotExists(result.presence.u8sContactId),
					...remoteContactUpdate
				}
			]);
		}
		if (Object.keys(changedProperties).length)
			getState().updateContact(result.presence.u8sContactId, changedProperties);
	}

	/**
	 * Interval function to clean up the attempts to subscribe ids.
	 * In case the server returns an empty array after the call to subscribe,
	 * to avoid that it's called forever.
	 */
	private cleanAttemptsToSubscribe() {
		if (!this.attemptToSubscribeContactIDs.size) return;

		for (const id of this.attemptToSubscribeContactIDs) this.subscribedContactIDs.add(id);

		this.attemptToSubscribeContactIDs = new Set();
	}

	/**
	 * Get a contact by its triple
	 * @param argument - the argument
	 */
	public async getUserByID(argument: AsnGetContactByEntryIDArgument): Promise<AsnGetContactByEntryIDResult | Error> {
		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				resolve(result as AsnGetContactByEntryIDResult);
			};
			this.socket.send("asnGetContactByEntryID", argument, callBack);
		});
	}

	/**
	 * Search a contact in server
	 * @param search - the search string
	 * @param pageOffset - the starting page offset (for paginating)
	 */
	public async searchInServer(search: string, pageOffset?: number): Promise<AsnFindContactsResult | Error> {
		const findOptions: AsnNetDatabaseFindOptions = {
			iDisplayNameFormat: 0,
			iFlags: 0,
			u8sAreaCodeOrCity: "",
			u8sCompanyName: "",
			u8sCustomerID: "",
			u8sDepartment: "",
			u8sEMail: "",
			u8sFirstName: "",
			u8sLastName: "",
			u8sPhoneNo: "",
			u8sStreetAddress: "",
			u8sUsername: "",
			u8slistDatabaseNames: [],
			u8spairlistCustomFieldsValues: [],

			iMaxNumEntries: ContactSearchLimit,
			optionalParams: [
				{
					key: "iPageOffset",
					value: { integerdata: pageOffset ?? 0 }
				},
				{
					key: "iPageSize",
					value: { integerdata: ContactsPerPage } // LC is 40
				}
			]
		};

		let searchString = search.replace(/ /g, "*");

		if (searchString.slice(-1) !== "*") searchString = searchString + "*";

		// Check if it's searching for a phone number
		if (search.search(/^[\W\d]*\d{2}[\W\d]*$/) >= 0) {
			if (searchString.indexOf("*") !== 0) searchString = "*" + searchString;

			findOptions.u8sPhoneNo = searchString;
			findOptions.iFlags = AsnNetDatabaseFindOptionIFlags.eMANUALSEARCH;
		} else {
			findOptions.u8sLastName = searchString;
			findOptions.iFlags =
				AsnNetDatabaseFindOptionIFlags.eRIGHTSCHECKED |
				AsnNetDatabaseFindOptionIFlags.eMULTIFIELDNAME |
				AsnNetDatabaseFindOptionIFlags.eONLYCTISERVERUSERS |
				AsnNetDatabaseFindOptionIFlags.eMETADIRECTORY;
		}

		const argument = new AsnFindContactsArgument({ findOptions });

		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) reject(result);
				else {
					this.subscribeUpdates(
						new Set(compact((result as AsnFindContactsResult).contactList.map((contact) => contact.u8sSIPAddress)))
					);
					resolve(result as AsnFindContactsResult);
				}
			};
			this.socket.send("asnFindContacts", argument, callBack);
		});
	}

	/**
	 * Get the MS Teams email from a contact (if any)
	 * @param params - the optional parameters to search in
	 * @returns - the email or undefined
	 */
	private getMSTeamsEmail(params?: AsnOptionalParameters): IMSTeamsEmail | undefined {
		if (!params) return undefined;

		const teams: IMSTeamsEmail = {
			email: "",
			value: 0
		};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, value] of Object.entries(params)) {
			if (key.search("External:MSTeams:") !== -1) {
				const split = key.split(":");
				teams.email = split[2];
				teams.value = value as unknown as number;
				break;
			}
		}
		return teams;
	}
}
